import React, { Component } from 'react';
import {NavLink} from 'react-router-dom';

import './NavItem.css';
export class NavItem extends Component {
    render() {
        return (
            <li className="Nav-item"><NavLink {... this.props} to={this.props.to} className={(navData) => (navData.isActive ? "active-style" : 'none')} exact={"true"}>{this.props.title}</NavLink></li>

        );
    }
}